import { render, staticRenderFns } from "./tournamentReports.vue?vue&type=template&id=066b8aa6&scoped=true"
import script from "./tournamentReports.vue?vue&type=script&lang=js"
export * from "./tournamentReports.vue?vue&type=script&lang=js"
import style0 from "./tournamentReports.vue?vue&type=style&index=0&id=066b8aa6&prod&scoped=true&lang=css"
import style1 from "@/css/ADThbh.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "@/css/orginalReportCard.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "@/css/ADTorginalReportCard.css?vue&type=style&index=3&prod&lang=css&external"
import style4 from "@/css/playerReportCard.css?vue&type=style&index=4&prod&lang=css&external"
import style5 from "@/css/ADTplayerReportCard.css?vue&type=style&index=5&prod&lang=css&external"
import style6 from "@/css/ADTresults.css?vue&type=style&index=6&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066b8aa6",
  null
  
)

export default component.exports